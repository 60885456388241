<template>
  <div :class="['page-banner', info.bgClass]">
    <div class="mask">
      <div class="mask_titlte">
        <h2 class="title">
          <span> {{ info.title }}</span>
          <div class="line"></div>
        </h2>
        <h2 class="subtitle">{{ info.subtitle }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 17:14:57
 *@version: V1.0.0
 *@description: banner
 *************************************************************************/
export default {
  name: "PageBanner",
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>

// 公司简介
.about-bg {
  background: url("../assets/images/about_bg.jpg") center/cover no-repeat;
}
// 联系我们
.contact-bg {
  background: url("../assets/images/bottom_bg.jpg") center/cover no-repeat;
}
// 药品信息(收费)
.products-bg {
  background: url("../assets/images/production_bg.jpg") center/cover no-repeat;
}
</style>
