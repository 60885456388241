<template>
  <div>
    <!-- banner -->
    <page-banner :info="bannerInfo"></page-banner>

    <!-- 公司简介 -->
    <company-profile></company-profile>
    <!-- 企业优势 -->
    <company-advantage></company-advantage>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 15:41:41
 *@version: V1.0.0
 *@description: 公司简介
 *************************************************************************/
import PageBanner from "@/components/PageBanner.vue";
import CompanyProfile from "@/components/CompanyProfile.vue";
import CompanyAdvantage from "@/components/CompanyAdvantage.vue";

export default {
  name: "Profiles",
  components: {
    PageBanner,
    CompanyProfile,
    CompanyAdvantage,
  },
  props: {},
  data() {
    return {
      bannerInfo: {
        title: "About Us",
        subtitle: "公司简介",
        bgClass: "about-bg",
      },
    };
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped></style>
